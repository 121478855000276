



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class VueBlockEdit extends Vue {
  @Prop({ type: String, required: true }) environmentId!: String
  @Prop({ type: String, required: true }) componentId!: String
  @Prop({ type: String, default: 'component' }) currentTab!: String
}
